import React from "react";
import {Container, Form, Grid} from "semantic-ui-react";
import JSONEditor from "./JSONEditor";

const CONTAINER_HEIGHT = 550; // originally 633

export default function ConfigurationViewerTab(props) {
    return (
        <Grid>
            <Grid.Row />
            <Grid.Row>
                <Grid.Column width={8}>
                    <Form>
                        <Form.Field>
                            <label>Current Configuration {props.currentConfigVersion ? `: Version ${props.currentConfigVersion}` : ""}</label>
                            <Container style={{overflowX: "auto", overflowY: "auto", maxHeight: CONTAINER_HEIGHT}} fluid>
                                <JSONEditor
                                    code={JSON.stringify(props.currentConfig, null, 4)}
                                    minHeight={CONTAINER_HEIGHT}
                                />
                            </Container>
                        </Form.Field>
                    </Form>
                </Grid.Column>
                <Grid.Column width={8}>
                    <Form>
                        <Form.Field>
                            <label>Selected Configuration</label>
                            <Container style={{overflowX: "auto", overflowY: "auto", maxHeight: CONTAINER_HEIGHT}} fluid>
                                <JSONEditor
                                    code={JSON.stringify(props.selectedConfig, null, 4)}
                                    minHeight={CONTAINER_HEIGHT}
                                />
                            </Container>
                        </Form.Field>
                    </Form>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );
};
