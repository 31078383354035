import React, {useState, useEffect} from "react";
import {Button, Container, Form, Grid, Icon, Message, Select, Tab, TextArea} from "semantic-ui-react";
import DifferencesTableTab from "./DifferencesTableTab";
import TVEManagerDataProvider from "../../../Services/TVEManagerDataProvider";

export default function ConfigurationVersionCreationTab(props) {
    const [selectedBrand, setSelectedBrand] = useState("");
    const [releaseNotes, setReleaseNotes] = useState("");
    const [releaseNotesRemainingCharacters, setReleaseNotesRemainingCharacters] = useState(500);
    const [additions, setAdditions] = useState([]);
    const [removals, setRemovals] = useState([]);
    const [updates, setUpdates] = useState([]);
    const [showTable, setShowTable] = useState(false);
    const [loading, setLoading] = useState(false);
    const [successMessage, setSuccessMessage] = useState("");
    const [errorMessage, setErrorMessage] = useState("");


    useEffect(function updateReleaseNotesRemainingCharacters() {
        if (releaseNotes.length >= 500) {
            setReleaseNotes(releaseNotes.slice(0, 500));
        }

        setReleaseNotesRemainingCharacters(500 - releaseNotes.length);
    }, [releaseNotes]);

    const formatModification = record => {
        if (typeof record.new_value === "object") {
            record.new_value = JSON.stringify(record.new_value, null, 4);
        }

        if (typeof record.old_value === "object") {
            record.old_value = JSON.stringify(record.old_value, null, 4);
        }

        return record;
    }

    useEffect(function getDiffOnBrandSelection() {
        setErrorMessage("");
        setSuccessMessage("");
        setAdditions([]);
        setRemovals([]);
        setUpdates([]);
        if (selectedBrand) {
            setShowTable(true);
            setLoading(true);
            TVEManagerDataProvider.getStagedChanges(selectedBrand).then(response => {
                console.log(response);
                setAdditions(response.additions.map(addition => formatModification(addition)));
                setRemovals(response.removals.map(removal => formatModification(removal)));
                setUpdates(response.updates.map(update => formatModification(update)));
            }).catch(error => {
                console.error(error);
                props.toast("error", `Error getting staged changes for ${selectedBrand}`);
                setErrorMessage(`Error getting staged changes for ${selectedBrand}`);
                setShowTable(false);
            }).finally(() => setLoading(false));
        }
    }, [selectedBrand]);

    const createNewVersionClicked = () => {
        if (selectedBrand) {
            setLoading(true);
            TVEManagerDataProvider.createNewConfig(selectedBrand, releaseNotes).then(response => {
                console.log(response);
                try {
                   setSuccessMessage(JSON.stringify(response.message, null, 4));
                } catch (e) {
                    setSuccessMessage(response.message);
                }
            }).catch(error => {
                console.error(error);
                props.toast("error", `There was an error creating a new config version for ${selectedBrand}`);
                setErrorMessage(`There was an error creating a new config version for ${selectedBrand}`);
            }).finally(() => {
                setLoading(false);
            });
        } else {
            props.toast("error", "You must select a brand before you can create a new version.");
        }
    };

    return (
        <Grid className="masterDetailContainer">
            <Grid.Column width={16} className="masterContainer">
                <Grid.Row>
                    <Form>
                        <Form.Group widths="equal">
                            <label>&nbsp;</label>
                            <Form.Field
                                label="Select a Brand"
                                fluid
                                control={Select}
                                options={props.brandOptions}
                                value={selectedBrand}
                                onChange={(event, {value}) => setSelectedBrand(value)}
                            />
                            <Form.Field>
                                <label>&nbsp;</label>
                                <Button onClick={createNewVersionClicked} primary disabled={(additions.length === 0 && removals.length === 0 && updates.length === 0) || releaseNotes.length === 0}>Create New Version</Button>
                            </Form.Field>
                        </Form.Group>
                    </Form>
                </Grid.Row>
                <Grid.Row>
                    {
                        successMessage !== "" ?
                            <Message icon><Icon name="check"/><pre>{successMessage}</pre></Message> :
                            errorMessage !== "" ?
                                <Message error icon><Icon name="x"/>{errorMessage}</Message> :
                                showTable ?
                                    <Container fluid>
                                        <Form>
                                            <Form.Field
                                                control={TextArea}
                                                label={`Release Notes (${releaseNotesRemainingCharacters} characters remaining)`}
                                                value={releaseNotes}
                                                onChange={(event, {value}) => setReleaseNotes(value)}
                                            />
                                        </Form>
                                        <Tab
                                            panes={[
                                                {
                                                    menuItem: {
                                                        key: "additions-table-tab",
                                                        content: <label>Additions</label>
                                                    },
                                                    render: () =>
                                                        <DifferencesTableTab
                                                            brand={selectedBrand}
                                                            differences={additions}
                                                            property="additions"
                                                            loading={loading}
                                                        />
                                                },
                                                {
                                                    menuItem: {
                                                        key: "removals-table-tab",
                                                        content: <label>Removals</label>
                                                    },
                                                    render: () =>
                                                        <DifferencesTableTab
                                                            brand={selectedBrand}
                                                            differences={removals}
                                                            property="removals"
                                                            loading={loading}
                                                        />
                                                },
                                                {
                                                    menuItem: {
                                                        key: "updates-table-tab",
                                                        content: <label>Updates</label>
                                                    },
                                                    render: () =>
                                                        <DifferencesTableTab
                                                            brand={selectedBrand}
                                                            differences={updates}
                                                            property="updates"
                                                            loading={loading}
                                                        />
                                                }
                                            ]}
                                        />
                                    </Container> : ""

                    }
                </Grid.Row>
            </Grid.Column>
        </Grid>
    )
}
