import React from "react";
import ReactTable from "react-table-v6";
import {Container, Icon} from "semantic-ui-react";

export default function BlackoutLocationsTable(props) {
    return (
        <ReactTable
            columns={[
                { accessor: "id", show: false },
                {
                    Header: "Region",
                    id: "region",
                    accessor: (originalRow, rowIndex) => {
                        return {icon: originalRow.icon, region: originalRow.region}
                    },
                    Cell: cell => <Container><Icon name={cell.value.icon}/>{cell.value.region}</Container>,
                },
                { Header: "Latitude", accessor: "lat" },
                { Header: "Longitude", accessor: "lon" },
                { Header: "IP Address", accessor: "ip" },
                { Header: "Zip Code", accessor: "zipcode" },
                { Header: "Country", accessor: "country" },
                { Header: "State", accessor: "state" },
                { Header: "Blackout?", accessor: "isBlackedOut", Cell: cell => {
                    return (
                        <Container textAlign="center">
                            <Icon name={cell.value ? "check" : "x"} />
                        </Container>)
                    }
                },
                { Header: "Blackout Type", accessor: "boType"}
            ]}
            defaultSorted={[{id: "id", asc: true }]}
            data={props.locations}
            loading={props.loading}
            filterable
            defaultFilterMethod={(filter, rows) => {
                if(rows[filter.id] !== null && rows[filter.id] !== undefined)
                    return rows[filter.id].toString().toLowerCase().includes(filter.value.toLowerCase())
            }}
            getTrProps={(state, rowInfo, column, instance) => {
                return {
                    onClick(event, handleOriginal) {
                        props.setSelectedKey(rowInfo.row.id);
                        props.setNewClicked(false);
                    },
                    style: {
                        background: rowInfo && rowInfo.row && props.selectedKey === rowInfo.row.id && !rowInfo.row.id.includes("auto") ? 'rgba(65, 83, 175, .5)' : '',
                    }
                }
            }}
            className="-striped -highlight"
            defaultPageSize={14}
        />
    );
};
