import React, {Component, createRef} from "react";
import {Grid, Sticky, Table, Message, Icon} from "semantic-ui-react";

export default class DifferencesTableTab extends Component {
    contextRef = createRef();

    render() {
        if (this.props.loading) {
            return <Message icon><Icon name="spinner" loading/>Loading configuration changes for {this.props.brand}...</Message>;
        }

        if (!Array.isArray(this.props.differences) || this.props.differences.length < 1) {
            return <Message icon><Icon name="balance scale"/>{this.props.brand} has no {this.props.property} in this config update.</Message>;
        }

        return (
            <div ref={this.contextRef}>
                <Grid.Column width={16} className="masterContainer">
                    <Sticky context={this.contextRef} offset={40}>
                        <Table celled fixed>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell width={2}>Config</Table.HeaderCell>
                                    <Table.HeaderCell width={1}>Version</Table.HeaderCell>
                                    <Table.HeaderCell width={1}>Property</Table.HeaderCell>
                                    <Table.HeaderCell width={5}>Old Value</Table.HeaderCell>
                                    <Table.HeaderCell width={5}>New Value</Table.HeaderCell>
                                    <Table.HeaderCell width={3}>Message</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                        </Table>
                    </Sticky>
                    <Table celled fixed striped>
                        <Table.Body>
                            {
                                this.props.differences.map(difference => {
                                    return (
                                        <Table.Row key={`table-row-${difference.config}-${difference.version}-${difference.property}`}>
                                            <Table.Cell width={2}><pre><b>{difference.config}</b></pre></Table.Cell>
                                            <Table.Cell width={1}><pre>{difference.version}</pre></Table.Cell>
                                            <Table.Cell width={1}><pre>{difference.property}</pre></Table.Cell>
                                            <Table.Cell width={5}><pre>{difference.old_value}</pre></Table.Cell>
                                            <Table.Cell width={5}><pre>{difference.new_value}</pre></Table.Cell>
                                            <Table.Cell width={3}><pre>{difference.message}</pre></Table.Cell>
                                        </Table.Row>
                                    );
                                })
                            }
                        </Table.Body>
                    </Table>
                </Grid.Column>
            </div>
        );
    }
};
