import React from 'react';
import {Header, Grid, Tab} from "semantic-ui-react";
import SecureTabPane from "../../SecureTabPane";
import AkamaiAdminCacheInvalidation from "./AkamaiAdminCacheInvalidation";


class CDNToolsMasterView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            metadata: {},
            environment: process.env.ENVIRONMENT_NAME
        }
    }

    handleSubmit = message => {
        this.setState({
            "message": message
        })
    };

    render() {
        return (
            <Grid style={{height: '100vh'}}>
                <Grid.Column width={16} className="masterContainer">
                    <Grid.Row>
                        <Tab
                            panes={[
                                {
                                    menuItem: "Medium Cache Purge",
                                    render: () =>
                                        <SecureTabPane
                                            component={
                                                <AkamaiAdminCacheInvalidation
                                                    environment={this.state.environment}
                                                    toast={this.props.toast}
                                                />
                                            }
                                            authState={this.props.authState}
                                            onFormSubmit={{}}
                                            user={this.props.user}
                                            permissionsRequired={["urn:cdn:cache"]}
                                            checkIfAuthorized={this.props.checkIfAuthorized}
                                            service={this.props.service}
                                            module={this.props.module}
                                            authenticated={this.props.authenticated}
                                            permissions={this.props.permissions}
                                            userPermissions={this.props.userPermissions}
                                            loadingPermissions={this.props.loadingPermissions}
                                        />
                                }
                            ]}/>
                    </Grid.Row>
                </Grid.Column>
            </Grid>
        );
    }
}

export default CDNToolsMasterView;
