import React, {useState, useEffect, useRef} from "react";
import {Link} from "react-router-dom";
import {Button, Container, Grid, Header, Tab, Form, Select, Input} from "semantic-ui-react";
import BlackoutDataProvider from "../../../Services/BlackoutDataProvider";
import TimeMachineDataProvider from "../../../Services/TimeMachineDataProvider";
import BlackoutSoupDetailView from "./BlackoutSoupDetailView";
import BlackoutLocationsTable from "./BlackoutLocationsTable";

const DEFAULT_SYSTEM_CONFIGURED = [
    {id: "auto-0", region: "Atlanta", country: "US", state: "GA", lat: 33.618, lon: -84.482, ip: "75.45.61.223", zipcode: "30329", boType: "Loading...", isBlackedOut: false, icon: "laptop"},
    {id: "auto-1", region: "Los Angeles", country: "US", state: "CA", lat: 34.064, lon: -118.239, ip: "161.149.146.201", zipcode: "90001", boType: "Loading...", isBlackedOut: false, icon: "laptop"},
    {id: "auto-2", region: "New York", country: "US", state: "NY", lat: 40.7056308, lon: -73.9780035, ip: "72.229.28.185", zipcode: "10001", boType: "Loading...", isBlackedOut: false, icon: "laptop"},
    {id: "auto-3", region: "St. Louis", country: "US", state: "MO", lat: 38.6537065, lon: -90.2477908, ip: "173.224.119.188", zipcode: "63101", boType: "Loading...", isBlackedOut: false, icon: "laptop"},
    {id: "auto-4", region: "Puerto Rico", country: "US", state: "PR", lat: 18.379, lon: -66.06, ip: "136.145.101.191", zipcode: "00600", boType: "Loading...", isBlackedOut: false, icon: "laptop"}
];

const DEFAULT_FEEDS = [
    {key: "tnt-east", text: "tnt-east", value: "tnt-east"},
    {key: "tnt-west", text: "tnt-west", value: "tnt-west"},
    {key: "tbs-east", text: "tbs-east", value: "tbs-east"},
    {key: "tbs-west", text: "tbs-west", value: "tbs-west"},
    {key: "nbatv", text: "nbatv", value: "nbatv"},
    {key: "brlive", text: "brlive", value: "brlive"},
    {key: "cnnx", text: "cnnx", value: "cnnx"}
];

const MS_PER_S = 1000;
const SECONDS_PER_MINUTE = 60;
const MS_PER_MINUTE = SECONDS_PER_MINUTE * MS_PER_S;
const REFRESH_FREQUENCY = 3 * MS_PER_MINUTE;

const COUNTRIES = {
    "": [],
    "US": [
        "", "AL","AK","AS","AZ","AR","CA","CO","CT","DE","DC","FM","FL","GA", "GU","HI","ID","IL","IN","IA","KS","KY",
        "LA","ME","MH","MD","MA", "MI","MN","MS","MO","MT","NE","NV","NH","NJ","NM","NY","NC","ND", "MP","OH","OK","OR",
        "PW","PA","PR","RI","SC","SD","TN","TX","UT", "VT","VI","VA","WA","WV","WI","WY"
    ],
    "CA": ["", "NL", "PE", "NS", "NB", "QC", "ON", "MB", "SK", "AB", "BC", "YT", "NT", "NU"]
};

let refreshInterval = null;

export default function BlackoutSoupMasterDetailView(props) {
    const [titleId, setTitleId] = useState("");
    const [tabSelected, setTabSelected] = useState("FEED");
    const [locations, setLocations] = useState([]);
    const [userConfiguredLocations, setUserConfiguredLocations] = useState([]);
    const [selectedFeed, setSelectedFeed] = useState("");
    const [currentShow, setCurrentShow] = useState({});
    const [loading, setLoading] = useState(true);
    const [region, setRegion] = useState("");
    const [zipcode, setZipcode] = useState("");
    const [countries, setCountries] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState("");
    const [states, setStates] = useState([]);
    const [selectedState, setSelectedState] = useState("");
    const [ipAddress, setIpAddress] = useState("");
    const [latitude, setLatitude] = useState("");
    const [longitude, setLongitude] = useState("");
    const [selectedKey, setSelectedKey] = useState(null);
    const [newClicked, setNewClicked] = useState(false);
    const [feeds, setFeeds] = useState([]);
    const [franchiseName, setFranchiseName] = useState("");
    const [title, setTitle] = useState("");
    const [showStartTime, setShowStartTime] = useState("");
    const [showDuration, setShowDuration] = useState("");
    const tabSelectedRef = useRef(tabSelected);
    tabSelectedRef.current = tabSelected;
    const selectedFeedRef = useRef(selectedFeed);
    selectedFeedRef.current = selectedFeed;
    const titleIdRef = useRef(titleId);
    titleIdRef.current = titleId;
    const userConfiguredLocationsRef = useRef(userConfiguredLocations);
    userConfiguredLocationsRef.current = userConfiguredLocations;

    useEffect(function initializeFeeds() {
        BlackoutDataProvider.init(process.env.BLACKOUT_URL);
        TimeMachineDataProvider.init(process.env.TIME_MACHINE_URL);

        setFeeds(DEFAULT_FEEDS);
        setSelectedFeed(DEFAULT_FEEDS[0].value);
    }, []);

    useEffect(function initializeLocations() {
        const systemDefinedLocations = DEFAULT_SYSTEM_CONFIGURED;

        let userConfiguredCookie = localStorage.getItem("userConfiguredLocations");

        if (userConfiguredCookie) {
            console.log("(BlackoutSoupMasterDetailView.initializeLocations) found userConfigured cookie in localStorage, setting from that: ", userConfiguredCookie);
            const userConfiguredLocationsFromCookie = JSON.parse(userConfiguredCookie);
            console.log("(BlackoutSoupMasterDetailView.initializeLocations) parsed userConfigured cookie in localStorage: ", userConfiguredLocations);

            if (Array.isArray(userConfiguredLocationsFromCookie)) {
                setUserConfiguredLocations(Array.from(new Set(userConfiguredLocationsFromCookie)));
            } else {
                console.error("(BlackoutSoupMasterDetailView.initializeLocations user-configured locations were non-null, but not a list either: ", userConfiguredLocations);
            }
        } else {
            console.log("(BlackoutSoupMasterDetailView.initializeLocations) no userConfigured cookie");
        }
        setLocations([...systemDefinedLocations, ...userConfiguredLocations]);
    }, []);


    useEffect(function populateCountries() {
        setCountries(Object.keys(COUNTRIES).map(country => {
            return {key: country, text: country, value: country};
        }));
    }, []);

    useEffect(function populateStates() {
        if (COUNTRIES[selectedCountry]) {
            setStates(COUNTRIES[selectedCountry].sort().map(state => {
                return {key: state, text: state, value: state};
            }));
            let selectedState = "";
            if (selectedKey && !newClicked) {
                const selectedRow = userConfiguredLocations.find(location => location.id === selectedKey);
                selectedState = selectedRow.state;
            }
            setSelectedState(selectedState);
        } else {
            setStates([]);
            setSelectedState("");
        }

    }, [selectedCountry]);

    useEffect(function triggerSavingUserConfiguredLocations() {
        saveUserConfiguredLocations();
    }, [userConfiguredLocations]);

    const saveUserConfiguredLocations = () => {
        localStorage.setItem("userConfiguredLocations", JSON.stringify(userConfiguredLocations));
    };

    useEffect(function setInitialRefreshTimer() {
        refreshInterval = setInterval(() => {
            console.log("BlackoutSoupMasterDetailView.setInitialRefreshTimer refreshing on timeout");
            refreshTables([...DEFAULT_SYSTEM_CONFIGURED, ...userConfiguredLocationsRef.current]);
        }, REFRESH_FREQUENCY);

        return function clearRefreshTimer() {
            console.log("(BlackoutSoupMasterDetailView.setInitialRefreshTimer) clearing the refresh interval.");
            clearInterval(refreshInterval);
            refreshInterval = null;
        }
    }, []);


    const refreshTables = (allLocations=[...DEFAULT_SYSTEM_CONFIGURED, ...userConfiguredLocations]) => {
        console.log("(BlackoutSoupMasterDetailView.doRefreshTables) beginning refresh");
        setLoading(true);
        new Promise(async resolve => {
            let updatedLocations = [];
            for (const location of allLocations) {
                location.isBlackedOut = false;
                location.boType = "";
                if (tabSelectedRef.current === "FEED") {
                    if (selectedFeedRef.current) {
                        if (location.zipcode && location.state && location.country) {
                            await BlackoutDataProvider.shouldBlackout(selectedFeedRef.current, null, "all", location.zipcode, location.state, location.country).then(result => {
                                location.boType = result.type;
                                location.isBlackedOut = result.enabled;
                            });
                        } else if (location.lat && location.lon && location.ip) {
                            await BlackoutDataProvider.shouldBlackout(selectedFeedRef.current, null, "all", null, null, null, location.lat, location.lon, location.ip).then(result => {
                                location.boType = result.type;
                                location.isBlackedOut = result.enabled;
                            });
                        }
                    }
                } else if (tabSelectedRef.current === "TITLE_ID") {
                    if (titleIdRef.current) {
                        if (location.zipcode && location.state && location.country) {
                            await BlackoutDataProvider.shouldBlackout(null, titleIdRef.current, "all", location.zipcode, location.state, location.country).then(result => {
                                location.boType = result.type;
                                location.isBlackedOut = result.enabled;
                            });
                        } else if (location.lat && location.lon && location.ip) {
                            await BlackoutDataProvider.shouldBlackout(null, titleIdRef.current, "all", null, null, null, location.lat, location.lon, location.ip).then(result => {
                                location.boType = result.type;
                                location.isBlackedOut = result.enabled;
                            });
                        }
                    }
                }
                updatedLocations.push(location);
            }
            resolve(updatedLocations);
        }).then(updatedLocations => {
            setLocations(updatedLocations);
        });
        setLoading(false);
    }

    useEffect(function onSelectedKeyChange() {
        if (selectedKey && !newClicked) {
            const selectedRow = userConfiguredLocations.find(location => location.id === selectedKey);
            if (selectedRow) {
                setRegion(selectedRow.region);
                setZipcode(selectedRow.zipcode);
                setSelectedCountry(selectedRow.country);
                setSelectedState(selectedRow.state);
                setLatitude(selectedRow.lat);
                setLongitude(selectedRow.lon);
                setIpAddress(selectedRow.ip);
            }
        }
    }, [selectedKey]);

    const refreshClicked = () => {
        updateCurrentShow();
        refreshTables();
    };

    const saveLocation = () => {
        console.log("selectedKey", selectedKey);
        if ((!zipcode ^ !selectedCountry) && selectedState) {
            console.log("zip xor country xor state", !!zipcode, !!selectedState, !!selectedCountry);
            props.toast("Error", "Please select a state and country and enter a zip code.");
        } else if ((!latitude ^ !longitude) && ipAddress) {
            console.log("latitude xor longitude xor ip address", !!latitude, !!longitude, !!ipAddress);
            props.toast("Error", "If setting location from latitude, longitude, and IP Address, please set all three.");
        } else if (latitude || zipcode) {
            console.log(`(BlackoutSoupDetailView.addLocation) adding location with region ${region}, zipcode ${zipcode}, country ${selectedCountry}, state ${selectedState}, latitude ${latitude}, and longitude ${longitude}`);
            if (newClicked || !selectedKey) {
                let idNumber = userConfiguredLocations.length;
                while (userConfiguredLocations.find(location => location.id === `user-${idNumber}`)) {
                    idNumber++;
                }
                let newLocation = {id: `user-${idNumber}` ,region, zipcode, country: selectedCountry, state: selectedState, lat: latitude, lon: longitude, ip: ipAddress, boType: "Loading...", isBlackedOut: false, icon: "user"}
                for (let location of userConfiguredLocations) {
                    if (newLocation.region === location.region && newLocation.zipcode === location.zipcode && newLocation.country === location.country && newLocation.state === location.state && newLocation.lat === location.lat && newLocation.lon === location.lon)
                        return;
                }
                setUserConfiguredLocations([...userConfiguredLocations, newLocation]);
                setLocations([...locations, newLocation]);
            } else if (!selectedKey.includes("auto")) {
                let updatedUserConfiguredLocations = userConfiguredLocations;
                for (const location of updatedUserConfiguredLocations) {
                    if (location.id === selectedKey) {
                        location.region = region;
                        location.zipcode = zipcode;
                        location.country = selectedCountry;
                        location.state = selectedState;
                        location.lat = latitude;
                        location.lon = longitude;
                        location.ip = ipAddress;
                    }
                }
                setUserConfiguredLocations(updatedUserConfiguredLocations);
                setLocations([...DEFAULT_SYSTEM_CONFIGURED, ...updatedUserConfiguredLocations]);
                saveUserConfiguredLocations();
            }

            console.log(`(BlackoutSoupMasterDetailView.addLocation) user-configured locations have been updated: `, userConfiguredLocations);
            console.log(`(BlackoutSoupMasterDetailView.addLocation) locations have been updated to: `, locations);
            refreshTables();
        } else {
            props.toast("Error", "Please enter either lat/lon coordinates or a region, state, and zip code.");
        }
    };

    const deleteLocation = () => {
        const updatedUserConfiguredLocations = userConfiguredLocations.filter(location => location.id !== selectedKey);
        setUserConfiguredLocations(updatedUserConfiguredLocations);
        setLocations([...DEFAULT_SYSTEM_CONFIGURED, ...updatedUserConfiguredLocations]);
        setNewClicked(true);
        // refreshTables();
    }

    useEffect(function refreshTablesOnLocationsLengthChange() {
        refreshTables();
    }, [userConfiguredLocations.length]);

    const addNewLocation = () => {
        setNewClicked(true);
        setRegion("");
        setZipcode("");
        setSelectedCountry("");
        setSelectedState("");
        setLatitude("");
        setLongitude("");
        setIpAddress("");
    };

    const handleTabChange = (event, {activeIndex}) => {
        console.log(event, activeIndex);
        const newTabSelected = activeIndex === 0 ? "FEED" : "TITLE_ID";
        if (newTabSelected !== tabSelected) {
            setTabSelected(newTabSelected);
        }
    }

    const updateCurrentShow = () => {
        if (selectedFeedRef.current) {
            TimeMachineDataProvider.getCurrentShow(selectedFeedRef.current).then(response => {
                console.log("(BlackoutSoupMasterDetailView.updateCurrentShowOnSelectedFeedChange): ", response);
                if (response.error) {
                    setCurrentShow({});
                    props.toast("Error", `Error getting the selected feed ${selectedFeed}: ${response.error}`);
                } else {
                    setCurrentShow(response);
                }
            });
        } else {
            setCurrentShow({});
        }
    }

    useEffect(function updateCurrentShowOnFeedChange() {
        updateCurrentShow();
        refreshTables();
    }, [selectedFeedRef.current]);

    const setStateFromCurrentShow = currentShow => {
        if (!currentShow || Object.keys(currentShow) < 1) {
            setFranchiseName("");
            setTitle("");
            setShowStartTime("");
            setShowDuration("");
        } else {
            setFranchiseName(currentShow.franchise_name || "");
            setTitle(currentShow.title || "");
            try {
                const duration = new Date(0);
                duration.setSeconds(parseInt(currentShow.show_duration));
                setShowDuration(duration.toISOString().substr(11, 8));
            } catch (error) {
                console.error(error);
                setShowDuration("");
            }
            try {
                const startTime = new Date(parseInt(currentShow.show_timestamp) * 1000);
                if (tabSelected === "FEED") {
                    setShowStartTime(startTime.toLocaleTimeString());
                }
            } catch (error) {
                console.error(error);
                setShowStartTime("");
            }
        }
    }

    useEffect(function updateStateCurrentShow() {
        setStateFromCurrentShow(currentShow);
    }, [currentShow]);

    return (
        <Grid>
            <Grid.Row>
                <Grid.Column width={12} className="masterContainer">
                    <Grid>
                        <Grid.Column floated="right" className="actionBarContainer">
                            <Container fluid textAlign="right">
                                <Button.Group>
                                    <Button color="black" as={Link} to={{pathname: "https://docsprod.turner.com/display/MPDTAS/Blackout+Soup"}} target="_blank">Help</Button>
                                    <Button.Or text=""/>
                                    <Button onClick={addNewLocation}>New Location</Button>
                                    <Button.Or text=""/>
                                    <Button onClick={refreshClicked}>Blackout Check</Button>
                                </Button.Group>
                            </Container>
                        </Grid.Column>
                    </Grid>
                    <Grid.Row>
                        <Tab
                            onTabChange={handleTabChange}
                            panes={[
                                {
                                    menuItem: "Check Blackout Status for Feed",
                                    render: () => {
                                        return (
                                            <Form>
                                                <br/>
                                                <Form.Group widths="equal">
                                                    <Form.Field
                                                        control={Select}
                                                        label="Feed"
                                                        options={feeds}
                                                        onChange={(event, {value}) => setSelectedFeed(value)}
                                                        value={selectedFeed}
                                                        fluid
                                                    />
                                                    <Form.Field
                                                        control={Input}
                                                        label="Franchise Name"
                                                        value={franchiseName}
                                                        readOnly
                                                    />
                                                    <Form.Field
                                                        control={Input}
                                                        label="Title"
                                                        value={title}
                                                        readOnly
                                                    />
                                                    <Form.Field
                                                        control={Input}
                                                        label="Show Start"
                                                        value={showStartTime}
                                                        readOnly
                                                    />
                                                    <Form.Field
                                                        control={Input}
                                                        label="Duration"
                                                        value={showDuration}
                                                        readOnly
                                                    />
                                                </Form.Group>
                                            </Form>
                                        );
                                    }
                                },
                                {
                                    menuItem: "Check Blackout Status for Title ID",
                                    render: () => {
                                        return (
                                            <Form>
                                                <br />
                                                <Form.Field
                                                    control={Input}
                                                    label="Title ID"
                                                    onChange={(event, {value}) => setTitleId(value)}
                                                    value={titleId}
                                                    fluid
                                                />
                                            </Form>
                                        );
                                    }
                                }
                            ]}
                        />
                    </Grid.Row>
                    <br />
                    <Grid.Row>
                        <BlackoutLocationsTable
                            locations={locations}
                            loading={loading}
                            setSelectedKey={setSelectedKey}
                            selectedKey={selectedKey}
                            setNewClicked={setNewClicked}
                        />
                    </Grid.Row>
                </Grid.Column>
                <Grid.Column width={4} className="detailsContainer">
                    <BlackoutSoupDetailView
                        toast={props.toast}
                        countries={countries}
                        selectedCountry={selectedCountry}
                        setSelectedCountry={setSelectedCountry}
                        states={states}
                        selectedState={selectedState}
                        setSelectedState={setSelectedState}
                        zipcode={zipcode}
                        setZipcode={setZipcode}
                        region={region}
                        setRegion={setRegion}
                        latitude={latitude}
                        setLatitude={setLatitude}
                        longitude={longitude}
                        setLongitude={setLongitude}
                        ipAddress={ipAddress}
                        setIpAddress={setIpAddress}
                        saveLocation={saveLocation}
                        deleteLocation={deleteLocation}
                        disabled={!(newClicked || (selectedKey && !selectedKey.includes("auto")))}
                    />
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );
};
