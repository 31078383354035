import React, {useState, useEffect} from "react";
import {Grid, Tab} from "semantic-ui-react";
import TVEManagerDataProvider from "../../../Services/TVEManagerDataProvider";
import ConfigurationVersionCreationTab from "./ConfigurationVersionCreationTab";
import ConfigurationVersionPublishingTab from "./ConfigurationVersionPublishingTab";

TVEManagerDataProvider.init(process.env.TVE_MANAGER_URL);

export default function ApplicationReleaseManager(props) {
    const [brandMap, setBrandMap] = useState({});
    const [brandOptions, setBrandOptions] = useState([]);

    useEffect(function getBrandsOnInit() {
        TVEManagerDataProvider.getBrands().then(response => {
            if (response.hasOwnProperty("brands") && Array.isArray(response.brands)) {
                const updatedBrandOptions = [];
                const updatedBrandMap = {};
                for (const brand of response.brands) {
                    updatedBrandOptions.push({key: brand.id, text: brand.displayName, value: brand.id});
                    updatedBrandMap[brand.id] = brand.displayName;
                }
                setBrandOptions(updatedBrandOptions);
                setBrandMap(updatedBrandMap);
            } else {
                setBrandOptions([]);
            }
        }).catch(error => {
            setBrandOptions([]);
            console.error(error);
        });
    }, []);

    return (
        <Grid>
            <Grid.Row>
                <Grid.Column width={16}>
                    <Tab
                        panes={[
                            {
                                menuItem: {
                                    key: "config-version-creation-tab",
                                    content: <label>Configuration Version Creation</label>
                                },
                                render: () =>
                                    <ConfigurationVersionCreationTab
                                        toast={props.toast}
                                        brandOptions={brandOptions}
                                    />
                            },
                            {
                                menuItem: {
                                    key: "config-version-publishing-tab",
                                    content: <label>Configuration Version Publishing</label>
                                },
                                render: () =>
                                    <ConfigurationVersionPublishingTab
                                        toast={props.toast}
                                        brandOptions={brandOptions}
                                        brandMap={brandMap}
                                    />
                            }
                        ]}
                    />
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );
};
