import React from "react";
import {Icon, Message, Tab} from "semantic-ui-react";
import ConfigurationDiffTab from "./ConfigurationDiffTab";
import ConfigurationViewerTab from "./ConfigurationViewerTab";

export default function ConfigurationComparisonTab(props) {
    return (
        !props.selectedBrand ? <Message>Please select a brand.</Message> :
        props.currentConfigVersion === "" ?
            <Message icon><Icon name="spinner" loading/>Loading live configuration file for {props.selectedBrand}...</Message> :
        !props.selectedConfigVersion && !props.isInitialConfig ? <Message>Version {props.currentConfigVersion} is currently live for {props.selectedBrand}. Please select a version with which to compare it.</Message> :
        props.loading ?
            <Message icon><Icon name="spinner" loading/>Loading configuration files for {props.selectedBrand}...</Message> :
        props.currentConfigVersion <= 0 ?
            <Message icon><Icon name="play"/>The brand {props.selectedBrand} has no published configuration files to diff or to view.</Message> :
            <Tab
                panes={[
                    {
                        menuItem: {
                            key: "configuration-publishing-diff-tab",
                            content: <label>Differences</label>
                        },
                        render: () =>
                            <ConfigurationDiffTab
                                currentVersion={props.currentConfigVersion}
                                selectedVersion={props.selectedConfigVersion}
                                differences={props.differences}
                                isInitialConfig={props.isInitialConfig}
                            />
                    },
                    {
                        menuItem: {
                            key: "configuration-publishing-viewer-tab",
                            content: <label>View Configurations (Slow!)</label>
                        },
                        render: () =>
                            <ConfigurationViewerTab
                                currentConfigVersion={props.currentConfigVersion}
                                currentConfig={props.currentConfig}
                                selectedConfig={props.selectedConfig}
                            />
                    }
                ]}
            />
    );
};
