import React, {Fragment} from "react";
import {Highlight, themes} from "prism-react-renderer";
import Editor from "react-simple-code-editor";
import Prism from "prismjs";
global.Prism = global.Prism ?? Prism;
import "prismjs/components/prism-markup-templating";
import "prismjs/components/prism-javascript";


export default function JSONEditor(props) {
    const highlight = code => {
        return (
            <Highlight prism={global.Prism} theme={themes.palenight} code={code} language="javascript">
                {({className, style, tokens, getLineProps, getTokenProps}) =>
                    <Fragment>
                        {
                            tokens.map((line, i) =>
                                <div {...getLineProps({line, key: i})}>
                                    {
                                        line.map((token, key) =>
                                            <span className={className} style={style} {...getTokenProps({token, key})} />)
                                    }
                                </div>)
                        }
                    </Fragment>
                }
            </Highlight>
        );
    };

    const styles = {
        root: {
            boxSizing: "border-box",
            fontFamily: 'Consolas, Monaco, "Andale Mono", "Ubuntu Mono", monospace',
            fontSize: 12,
            borderStyle: "solid",
            minHeight: props.minHeight || 436,
            ...themes.palenight.plain
        }
    };

    return (
        <Editor
            value={props.code}
            onValueChange={props.setCode}
            highlight={highlight}
            padding={10}
            style={styles.root}
        />
    );
};
