import React, {useState, useEffect} from "react";
import {Button, Container, Form, Grid, Icon, Message, Select} from "semantic-ui-react";
import TVEManagerDataProvider from "../../../Services/TVEManagerDataProvider";
import ConfigurationComparisonTabs from "./ConfigurationComparisonTabs";

export default function ConfigurationVersionPublishingTab(props) {
    const [selectedBrand, setSelectedBrand] = useState("");
    const [configVersionOptions, setConfigVersionOptions] = useState([]);
    const [previousConfigVersion, setPreviousConfigVersion] = useState("");
    const [currentLatestConfigVersion, setCurrentLatestConfigVersion] = useState("");
    const [selectedConfigVersion, setSelectedConfigVersion] = useState("");
    const [selectedConfig, setSelectedConfig] = useState({});
    const [currentLatestConfig, setCurrentLatestConfig] = useState({});
    const [differences, setDifferences] = useState([]);
    const [loading, setLoading] = useState(false);
    const [isInitialConfig, setIsInitialConfig] = useState(false);
    const [showConfigurationPublishedMessage, setShowConfigurationPublishedMessage] = useState(false);

    useEffect(function onSelectBrand() {
        setSelectedConfigVersion("");
        setCurrentLatestConfig({});
        setCurrentLatestConfigVersion("");
        setPreviousConfigVersion("");
        setShowConfigurationPublishedMessage(false);
        if (selectedBrand) {
            TVEManagerDataProvider.getLatestConfigData(selectedBrand).then(response => {
                if (response.result) {
                    setCurrentLatestConfigVersion(response.result.currentLatest);
                    if (response.result.hasOwnProperty("versionList") && response.result.versionList.length > 0) {
                        setConfigVersionOptions(response.result.versionList.map(version => {
                            return {key: version, text: version, value: version};
                        }));
                    } else {
                        setConfigVersionOptions([]);
                    }
                } else {
                    setCurrentLatestConfigVersion("");
                    setConfigVersionOptions([]);
                }
            }).catch(error => {
                console.error(error);
            });
        } else {
            setConfigVersionOptions([]);
            setCurrentLatestConfig({});
        }
    }, [selectedBrand]);

    useEffect(function updateLatestConfig() {
        if (selectedBrand && currentLatestConfigVersion !== "") {
            setLoading(true);
            TVEManagerDataProvider.getConfig(selectedBrand, currentLatestConfigVersion).then(response => {
                console.log(response);
                if (response.error && response.error.response && response.error.response.data && response.error.response.data.message) {
                    setIsInitialConfig(response.error.response.data.message.includes("Config not found"));
                } else {
                    setIsInitialConfig(false);
                }
                setCurrentLatestConfig(response);

            }).catch(error => {
                console.error(error);
                setCurrentLatestConfig({});
                console.log(error);

            }).finally(() => {
                setLoading(false);
            });
        } else {
            setCurrentLatestConfig({});
        }
    }, [currentLatestConfigVersion, selectedBrand]);

    useEffect(function updateSelectedConfig() {
        if (selectedBrand && selectedConfigVersion) {
            setLoading(true);
            TVEManagerDataProvider.getConfig(selectedBrand, selectedConfigVersion).then(response => {
                setSelectedConfig(response);
            }).finally(() => {
                setLoading(false);
            });
        } else {
            setSelectedConfig({});
        }
    }, [selectedBrand, selectedConfigVersion]);

    useEffect(function generateDiffOnConfigUpdate() {
        if (currentLatestConfigVersion && selectedConfigVersion) {
            generateDiff();
        }
    }, [JSON.stringify(selectedConfig), JSON.stringify(currentLatestConfig)]);

    const generateDiff = () => {
        new Promise(resolve => {
            setDifferences([]);
            setLoading(true);
            resolve();
        }).then(() => {
            const newDifferences = [];
            if (currentLatestConfig && selectedConfig) {
                for (const key of Object.keys(currentLatestConfig)) {
                    if (key === "mvpd") {
                        const currentVersionMVPDs = currentLatestConfig.mvpd.reduce((accumulator, mvpd) => {
                            accumulator[mvpd.id] = mvpd;
                            return accumulator;
                        }, {});
                        const selectedVersionMVPDs = selectedConfig.mvpd.reduce((accumulator, mvpd) => {
                            accumulator[mvpd.id] = mvpd;
                            return accumulator;
                        }, {});
                        const mvpdDifferences = Object.keys(currentVersionMVPDs).filter(mvpd => !selectedVersionMVPDs.hasOwnProperty(mvpd) || JSON.stringify(currentVersionMVPDs[mvpd]) !== JSON.stringify(selectedVersionMVPDs[mvpd]));
                        for (const mvpd of mvpdDifferences) {
                            const key = `mvpd > ${mvpd}`;
                            const currentVersionMVPDData = currentVersionMVPDs[mvpd];
                            const selectedVersionMVPDData = selectedVersionMVPDs.hasOwnProperty(mvpd) ? selectedVersionMVPDs[mvpd] : null;

                            if (selectedVersionMVPDData != null) {
                                for (const mvpdKey of Object.keys(currentVersionMVPDData)) {
                                    if (selectedVersionMVPDData.hasOwnProperty(mvpdKey)) {
                                        if (typeof currentVersionMVPDData[mvpdKey] === "object" && (typeof selectedVersionMVPDData[mvpdKey] === "object" || selectedVersionMVPDData[mvpdKey] == null)) {
                                            // These should only ever be platform properties
                                            const currentVersionPlatform = currentVersionMVPDData[mvpdKey];
                                            const selectedVersionPlatform = selectedVersionMVPDData[mvpdKey];
                                            for (const platformKey of Object.keys(currentVersionPlatform)) {
                                                // Deletion of key in platform
                                                if (!selectedVersionPlatform.hasOwnProperty(platformKey)) {
                                                    newDifferences.push({
                                                        key: `${key} > ${mvpdKey} > ${platformKey}`,
                                                        currentVersion: currentVersionPlatform[platformKey],
                                                        selectedVersion: null
                                                    });
                                                } else {
                                                    // Update of key in platform
                                                    if (currentVersionPlatform[platformKey] !== selectedVersionPlatform[platformKey]) {
                                                        newDifferences.push({
                                                            key: `${key} > ${mvpdKey} > ${platformKey}`,
                                                            currentVersion: currentVersionPlatform[platformKey],
                                                            selectedVersion: selectedVersionPlatform[platformKey]
                                                        });
                                                    }
                                                }
                                            }
                                            for (const platformKey of Object.keys(selectedVersionPlatform)) {
                                                // Addition of key in platform
                                                if (!currentVersionPlatform.hasOwnProperty(platformKey)) {
                                                    newDifferences.push({
                                                        key: `${key} > ${mvpdKey} > ${platformKey}`,
                                                        currentVersion: null,
                                                        selectedVersion: selectedVersionPlatform[platformKey]
                                                    });
                                                }
                                            }
                                        } else {
                                            if (JSON.stringify(currentVersionMVPDData[mvpdKey]) !== JSON.stringify(selectedVersionMVPDData[mvpdKey])) {
                                                // Update of old key in MVPD
                                                newDifferences.push({
                                                    key: `${key} > ${mvpdKey}`,
                                                    currentVersion: currentVersionMVPDData[mvpdKey],
                                                    selectedVersion: selectedVersionMVPDData[mvpdKey]
                                                });
                                            }
                                        }
                                    } else {
                                        // Deletion of old key in MVPD
                                        newDifferences.push({
                                            key: `${key} > ${mvpdKey}`,
                                            currentVersion: currentVersionMVPDData[mvpdKey],
                                            selectedVersion: null
                                        });
                                    }
                                }
                                // Addition of keys in MVPD
                                for (const mvpdKey of Object.keys(selectedVersionMVPDData)) {
                                    if (!currentVersionMVPDData.hasOwnProperty(mvpdKey)) {
                                        newDifferences.push({
                                            key: `${key} > ${mvpdKey}`,
                                            currentVersion: null,
                                            selectedVersion: selectedVersionMVPDData[mvpdKey]
                                        });
                                    }
                                }
                            } else {
                                // Deletion of old MVPD
                                newDifferences.push({
                                    key,
                                    currentVersion: currentVersionMVPDData,
                                    selectedVersion: selectedVersionMVPDData
                                });
                            }
                        }
                        // Addition of new MVPD
                        for (const mvpd of Object.keys(selectedVersionMVPDs)) {
                            if (!currentVersionMVPDs.hasOwnProperty(mvpd)) {
                                newDifferences.push({
                                    key: `mvpd > ${mvpd}`,
                                    currentVersion: null,
                                    selectedVersion: selectedVersionMVPDs[mvpd]
                                });
                            }
                        }
                    } else {
                        if (key !== "releaseNotes" && currentLatestConfig[key] !== selectedConfig[key]) {
                            newDifferences.push({
                                key,
                                currentVersion: currentLatestConfig[key],
                                selectedVersion: selectedConfig[key]
                            });
                        }
                    }
                }
            } else {
                console.log(currentLatestConfig, selectedConfig);
            }
            return newDifferences;
        }).then(newDifferences => {
            setDifferences(newDifferences);
        }).finally(() => {
            setLoading(false);
        });
    };

    const publishVersion = () => {
        if (selectedBrand && selectedConfigVersion) {
            setPreviousConfigVersion(currentLatestConfigVersion);
            TVEManagerDataProvider.publishConfig(selectedBrand, selectedConfigVersion).then(response => {
                console.log(response);
                setCurrentLatestConfigVersion(selectedConfigVersion);
                setShowConfigurationPublishedMessage(true);
            }).catch(error => {
                console.log(error);
            });
        }
    };

    return (
        <Grid className="masterDetailContainer">
            <Grid.Column className="masterContainer" width={16}>
                <Grid.Row>
                    <Form>
                        <Form.Group widths="equal">
                            <Form.Field
                                label="Select Brand"
                                control={Select}
                                options={props.brandOptions}
                                value={selectedBrand}
                                onChange={(event, {value}) => setSelectedBrand(value)}
                            />
                            <Form.Field>
                                <Form.Group widths="equal">
                                    <Form.Field
                                        label="Select Version"
                                        control={Select}
                                        options={configVersionOptions}
                                        value={selectedConfigVersion}
                                        onChange={(event, {value}) => setSelectedConfigVersion(value)}
                                    />
                                    <Form.Field>
                                        <label>&nbsp;</label>
                                        <Button primary fluid onClick={publishVersion}>Publish</Button>
                                    </Form.Field>
                                </Form.Group>
                            </Form.Field>
                        </Form.Group>
                    </Form>
                </Grid.Row>
                <Grid.Row>
                    {
                        showConfigurationPublishedMessage ?
                            <Message icon><Icon name="check" />
                                {
                                    previousConfigVersion !== "" ? <Container>Version {previousConfigVersion} is deactivated and version {currentLatestConfigVersion} is live for {selectedBrand}.</Container> :
                                        <Container>The configuration version {currentLatestConfigVersion} for {selectedBrand} has been published.</Container>
                                }
                            .
                            </Message> : ""
                    }
                </Grid.Row>
                {
                    Object.keys(selectedConfig).length > 0 ?
                        selectedConfigVersion.length < 1 ? "" :
                        selectedConfig.hasOwnProperty("releaseNotes") && selectedConfig.releaseNotes.length > 0 ?
                            <Message>
                                <Message.Header>{props.brandMap[selectedBrand]} Version {selectedConfigVersion} Release Notes</Message.Header>
                                <Message.Content>{selectedConfig.releaseNotes}</Message.Content>
                            </Message> :
                            <Message>
                                <Message.Content>Version {selectedConfigVersion} for {props.brandMap[selectedBrand]} does not have release notes.</Message.Content>
                            </Message> :
                            ""
                }
                <Grid.Row>
                    <ConfigurationComparisonTabs
                        currentConfigVersion={currentLatestConfigVersion}
                        currentConfig={currentLatestConfig}
                        selectedConfigVersion={selectedConfigVersion}
                        selectedConfig={selectedConfig}
                        selectedBrand={selectedBrand}
                        differences={differences}
                        loading={loading}
                        isInitialConfig={isInitialConfig}
                    />
                </Grid.Row>
            </Grid.Column>
        </Grid>
    );
};
