import React, {Component, createRef} from "react";
import {Sticky, Table, Grid, Message, Icon} from "semantic-ui-react";

export default class ConfigurationDiffTab extends Component {
    constructor(props) {
        super(props);
    }
    contextRef = createRef();

    render() {
        if (this.props.isInitialConfig) {
            return (
                <Message icon>
                    <Icon name="info" />
                    There are no configurations to compare for this brand. Please publish a version first.
                </Message>
            );
        }

        return (
            <div ref={this.contextRef}>
                <Grid.Column width={16} className="masterContainer">
                    <Sticky context={this.contextRef} offset={40}>
                        <Table celled fixed>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell>Key</Table.HeaderCell>
                                    <Table.HeaderCell>Current Version ({this.props.currentVersion})</Table.HeaderCell>
                                    <Table.HeaderCell>Version {this.props.selectedVersion}</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                        </Table>
                    </Sticky>
                    <Table celled fixed striped>
                        <Table.Body>
                            {
                                this.props.differences.map(difference => {
                                    return (
                                        <Table.Row key={`table-row-${difference.key}`}>
                                            <Table.Cell><pre><b>{difference.key}</b></pre></Table.Cell>
                                            <Table.Cell><pre>{typeof difference.currentVersion === "object" ? JSON.stringify(difference.currentVersion, null, 4) : difference.currentVersion.toString()}</pre></Table.Cell>
                                            <Table.Cell><pre>{typeof difference.selectedVersion === "object" ? JSON.stringify(difference.selectedVersion, null, 4) : difference.selectedVersion.toString()}</pre></Table.Cell>
                                        </Table.Row>
                                    );
                                })
                            }
                        </Table.Body>
                    </Table>
                </Grid.Column>
            </div>
        );
    }
};
