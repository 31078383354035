import React from "react";
import {Button, Form, Input, Select, Divider} from "semantic-ui-react";

export default function BlackoutSoupDetailView(props) {
    return (
        <Form>
            <Form.Group widths="equal">
                <Form.Field align="left">
                    <label>&nbsp;</label>
                    <Button
                        onClick={() => props.saveLocation()}
                        disabled={props.disabled}
                    >Save</Button>
                </Form.Field>
                <Form.Field align="right">
                    <label>&nbsp;</label>
                    <Button
                        onClick={() => props.deleteLocation()}
                        disabled={props.disabled}
                        color="red"
                    >Delete</Button>
                </Form.Field>
            </Form.Group>
            <Form.Field
                control={Input}
                label="Region"
                disabled={props.disabled}
                value={props.region}
                onChange={(event, {value}) => props.setRegion(value)}
                fluid
            />
            <Divider horizontal>Option 1</Divider>
            <Form.Field
                control={Input}
                label="Zip Code"
                disabled={props.disabled}
                value={props.zipcode}
                onChange={(event, {value}) => props.setZipcode(value)}
                fluid
            />
            <Form.Field
                control={Select}
                options={props.countries}
                label="Country"
                disabled={props.disabled}
                value={props.selectedCountry}
                fluid
                search
                onChange={(event, {value}) => props.setSelectedCountry(value)}
            />
            <Form.Field
                control={Select}
                options={props.states}
                label="State"
                disabled={props.disabled}
                value={props.selectedState}
                fluid
                search
                onChange={(event, {value}) => props.setSelectedState(value)}
            />
            <Divider horizontal>Option 2</Divider>
            <Form.Field
                control={Input}
                label="Latitude"
                disabled={props.disabled}
                value={props.latitude}
                fluid
                onChange={(event, {value}) => props.setLatitude(value)}
            />
            <Form.Field
                control={Input}
                label="Longitude"
                disabled={props.disabled}
                value={props.longitude}
                fluid
                onChange={(event, {value}) => props.setLongitude(value)}
            />
            <Form.Field
                control={Input}
                label="IP Address"
                disabled={props.disabled}
                value={props.ipAddress}
                fluid
                onChange={(event, {value}) => props.setIpAddress(value)}
            />
        </Form>
    );
};
